<template>

    <div class="chart-wrap relative" ref="container-chart">
        <highcharts :options="options" ref="gaugeChart" class="chart-component" />
        <div class="center-absolute" v-if="$slots.default"><slot /></div>
    </div>

</template>

<script>
    export default {
        props: ['data', 'limit'],
        data() {
            return {
                options: {
                    chart: {
                        type: 'solidgauge',
                        backgroundColor: 'rgba(255,255,255,0)',
                        margin: [0,0,0,0],
                    },
                    credits: { enabled: false },
                    legend: { enabled: false },
                    title: { text: '' },
                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ // Track for Move
                            outerRadius: '100%',
                            innerRadius: '95%',
                            backgroundColor:'#2D363C',
                            borderWidth: 0
                        }, 
                         { // Track for Exercise
                         outerRadius: '94%',
                         backgroundColor: '#2D363C',
                         innerRadius: '89%',
                         borderWidth: 0
                         }
                        ]
                    },
                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: [],
                        plotLines: [],
                    },
                    tooltip:{
                        enabled: false
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true
                        }
                    },
                    series: [
                        {
                            name: 'Move',
                            radius: '100%',
                            innerRadius: '95%',
                            data: [{y:0, color:'#2363C9'},{y:0, color:'#59BEB6'}]
                        },
                        {
                            name: 'Exercise',
                            radius: '94%',
                            innerRadius: '89%',
                            data: [{y:0, color:'#F9B254'}]
                        }
                    ]
                }
            }
        },
        mounted() {
            this.updateChart();     
            if (this.limit) { this.updateLimit() }
        },
        methods:{
            updateChart() {
                this.chart.series[0].points[1].update(this.data[0][0]);
                this.chart.series[0].points[0].update(this.data[0][1]);
                this.chart.series[1].points[0].update(this.data[1]);
            },
            updateLimit() {
                this.chart.yAxis[0].removePlotLine('limit')
                this.chart.yAxis[0].addPlotLine({ 
                    color: this.tailwindColors.warning.default, 
                    width: 3, 
                    value: this.limit,
                    zIndex:10,
                    id:'limit'
                })
            }
        },
        computed: {
            chart() { return this.$refs['gaugeChart'].chart }
        },
        watch: {
            data(n, o) {
                if (JSON.stringify(n) !== JSON.stringify(o)) {
                    this.updateChart();
                }
            },
            limit(n, o) {
                if (JSON.stringify(n) !== JSON.stringify(o)) { this.updateLimit() }
            }
        },
    }

</script>

<style lang="scss">

    .chart-wrap {
        height: 100% !important;
        width: 100% !important;
        .highcharts-container {
            height: 100% !important;
            width: 100% !important;
        }
    }
    .chart-wrap > .chart-component {
        height: 100% !important;
        width: 100% !important;
    }

</style>
